import React from 'react'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';

function Category(props) {
    return (
        <Card >
            <CardContent onClick={() => {
                props.whenClicked(props.category)
            }}>
                <Typography gutterBottom variant="p" >
                    {props.category?.name || ""}
                </Typography>

            </CardContent>
        </Card>
    )
}

export default Category