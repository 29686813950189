import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { Button, getTableSortLabelUtilityClass } from '@mui/material';
import Fab from '@mui/material/Fab';
import NavigationIcon from '@mui/icons-material/Navigation';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { ref, set, onValue } from "firebase/database";
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import fireDb from "../firebase"
import Dishes from './dishes';
import {
    BrowserRouter as Router,
    Link,
    useLocation
} from "react-router-dom";
import Category from './Category';


const CategoryWarapper = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));




export default function Menu() {


    const [expanded, setExpanded] = React.useState(false);
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    const [categoriesd, setCategoriesd] = React.useState([])
    const [dishes, setDishes] = React.useState([])
    const [focusedDishes, setFocusedDishes] = React.useState([])
    const [selectedCategory, setSelectedCategory] = React.useState({})
    const [orderItems, setOrderItems] = React.useState([])

    const [dishesOpen, setDishesOpen] = React.useState(false);

    const handleDishesOpen = () => {
        setDishesOpen(true);
    };

    const handleDishesClose = (orderItem) => {
        setDishesOpen(false);
        if (orderItem.name)
            setOrderItems([...orderItems, orderItem])
    };


    React.useEffect(() => {
        const starCountRef = ref(fireDb, 'menus/categories/SMP-BIZ/SMP-BRCH');
        onValue(starCountRef, (snapshot) => {
            let cats = snapshot.val();
            cats = Object.values(cats)
            if (categoriesd.length !== cats.length) {
                setCategoriesd(cats)
            }

        });
    }, [categoriesd]);

    React.useEffect(() => {
        const starCountRef = ref(fireDb, 'menus/dishes/SMP-BIZ/SMP-BRCH');
        onValue(starCountRef, (snapshot) => {
            let dis = snapshot.val();
            dis = Object.values(dis || {})
            if (dis.length !== dishes.length) {
                setDishes(dis)
            }

        });
    }, [dishes]);


    const categoryClicked = (category) => {
        const fDishes = dishes.filter(dish => {
            return dish.catCode == category.code
        })
        setSelectedCategory(category)
        setFocusedDishes(fDishes)
        setDishesOpen(true)
    }

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const getTotalPrice =(orderItems) => {
        var price = 0
         orderItems.forEach(item => {
          price=  item.price + price
        })
        return price
    }

    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                {orderItems.map((orderItem, index) => (
                    <ListItem key={index} disablePadding>
                        <ListItemButton>
                            <ListItemText primary={orderItem.name} />
                            <ListItemText primary={orderItem.spiceLevel} />
                            <ListItemText primary={orderItem.price} />
                        </ListItemButton>
                    </ListItem>
                ))}

                <ListItem key={'3434'} disablePadding>
                    <ListItemButton>
                        <ListItemText primary="Total" />
                        <ListItemText primary={getTotalPrice(orderItems)} />
                    </ListItemButton>
                </ListItem>
            </List>
            <Divider />
        </Box>
    );

    function Categories(props) {


        return (
            <React.Fragment>
                <Grid container spacing={1}>
                    <Grid container item spacing={3}>
                        {(props.categories || []).map((cat, index) => {

                            if (index >= 0 && index < 3) {
                                return (<Grid item xs={4} spacing={2}>
                                    <CategoryWarapper>
                                        <Category category={cat} whenClicked={categoryClicked} />
                                    </CategoryWarapper>
                                </Grid>)
                            }
                        })}
                    </Grid>
                    <Grid container item spacing={3}>
                        {(props.categories || []).map((cat, index) => {

                            if (index >= 3 && index < 6) {
                                return (<Grid item xs={4} spacing={2}>
                                    <CategoryWarapper>
                                        <Category category={cat} whenClicked={categoryClicked} />
                                    </CategoryWarapper>
                                </Grid>)
                            }
                        })}
                    </Grid>
                    <Grid container item spacing={3}>
                        {(props.categories || []).map((cat, index) => {

                            if (index >= 6 && index < 9) {
                                return (<Grid item xs={4} spacing={2}>
                                    <CategoryWarapper>
                                        <Category category={cat} whenClicked={categoryClicked} />
                                    </CategoryWarapper>
                                </Grid>)
                            }
                        })}
                    </Grid>
                    <Grid container item spacing={3}>
                        {(props.categories || []).map((cat, index) => {

                            if (index >= 9 && index < 12) {
                                return (<Grid item xs={4} spacing={2}>
                                    <CategoryWarapper>
                                        <Category category={cat} whenClicked={categoryClicked} />
                                    </CategoryWarapper>
                                </Grid>)
                            }
                        })}
                    </Grid>
                    <Grid container item spacing={3}>
                        {(props.categories || []).map((cat, index) => {

                            if (index >= 12 && index < 15) {
                                return (<Grid item xs={4} spacing={2}>
                                    <CategoryWarapper>
                                        <Category category={cat} whenClicked={categoryClicked} />
                                    </CategoryWarapper>
                                </Grid>)
                            }
                        })}
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const [alignment, setAlignment] = React.useState('left');

    const handleAlignment = (event, newAlignment) => {
        setAlignment(newAlignment);
    };
    function useQuery() {
        const { search } = useLocation();

        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    let query = useQuery();
    return (
        <div>

            <Typography>
                Table Number {query.get("table")}
            </Typography>
            <Box sx={{ flexGrow: 1 }}>
                <Categories categories={categoriesd} />
            </Box>
            <Dishes

                whenAddToOrder={handleDishesClose}
                category={selectedCategory}
                dishes={focusedDishes}
                open={dishesOpen}
                onClose={handleDishesClose}
            />
            <div>

                <React.Fragment sx={{
                    position: 'relative'
                }}>
                    <Badge sx={{
                        position: "fixed",
                        bottom: (theme) => theme.spacing(7),
                        right: (theme) => theme.spacing(2)
                    }} anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }} color="secondary" badgeContent={orderItems.length}>
                        <Fab sx={{
                            position: "fixed",
                            bottom: (theme) => theme.spacing(2),
                            right: (theme) => theme.spacing(2)
                        }} variant="extended" size="medium" color="primary" aria-label="add" onClick={toggleDrawer('right', true)}>
                            <NavigationIcon sx={{ mr: 1 }} />



                            My Order
                        </Fab>
                    </Badge>
                    <Drawer
                        anchor={'right'}
                        open={state['right']}
                        onClose={toggleDrawer('right', false)}
                    >
                        {list('right')}
                    </Drawer>
                </React.Fragment>

            </div>
        </div>
    );
}