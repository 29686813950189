// import React from 'react'
// import { useState, useRef } from "react";
// import { QRCodeCanvas } from "qrcode.react";

// export default function TableGenerator() {

//     const [url, setUrl] = useState("");

//     const qrRef = useRef();

//     const downloadQRCode = (e) => {
//         e.preventDefault();
//         let canvas = qrRef.current.querySelector("canvas");
//         let image = canvas.toDataURL("image/png");
//         let anchor = document.createElement("a");
//         anchor.href = image;
//         anchor.download = `qr-code.png`;
//         document.body.appendChild(anchor);
//         anchor.click();
//         document.body.removeChild(anchor);
//         setUrl("");
//     };

//     const qrCodeEncoder = (e) => {
//         setUrl(e.target.value);
//     };

//     const qrcode = (
//         <QRCodeCanvas
//             id="qrCode"
//             value={url}
//             size={300}
//             bgColor={"#fff"}
//             level={"H"}
//         />
//     );
//     return (
//         <div className="qrcode__container">
//             <div>Menu Galore Table QR Code Generator</div>
//             <div>

//             </div>
//             <div ref={qrRef}>{qrcode}</div>
//             <div className="input__group">
//                 <form onSubmit={downloadQRCode}>
//                     <label>Enter URL</label>
//                     <input
//                         type="text"
//                         value={url}
//                         onChange={qrCodeEncoder}
//                         placeholder="https://hackernoon.com"
//                     />
//                     <button type="submit" disabled={!url}>
//                         Download QR code
//                     </button>
//                 </form>
//             </div>
//         </div>
//     )
// }


import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { QRCodeCanvas } from "qrcode.react";
import { Paper } from '@mui/material';
import { width } from '@mui/system';


const steps = ['Select Business', 'Select Branch', 'Select Table', " Get QR Code"];


export default function TableGenerator() {
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());

    const businessProfiles = [
        {
            name: "Juice and Ice-cream corner",
            code: "JAIC",
            branches: [{
                name: "Terneit Branch",
                code: "tnt",
                tables: 7
            },
            {
                name: "Melton Branch",
                code: "mlt",
                tables: 7
            }
            ]
        },
        {
            name: "Restourant",
            code: "rtnt",
            branches: [{
                name: "Cobbleank Branch",
                code: "cbl",
                tables: 7
            },
            {
                name: "SouthBank Branch",
                code: "sbb",
                tables: 7
            }
            ]
        }
    ]

    const [business, setBusiness] = React.useState('');
    const [branch, setBranch] = React.useState('');
    const [table, setTable] = React.useState({ name: "" });
    const [url, setUrl] = React.useState("");
    const qrRef = React.useRef();

    const handleBusinessChange = (event) => {
        setBusiness(event.target.value);
    };

    const handleBranchChange = (event) => {
        setBranch(event.target.value);
    };

    const isStepOptional = (step) => {
        return step === 1;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;

        if (activeStep === 2) {
            if (branch != "" && business != "" && table.name != "") {
                setUrl("https://menugalore.com.au/menu?business=" + business + "&branch=" + branch + "&table=" + table.name)
            } else {
                alert("Either business or nrahcn or table not selected!")
            }
        }
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const getTableSortLabelUtilityClass = (bizCode, brCode) => {
        let biz = businessProfiles.find(business => {
            return business.code == bizCode
        })

        if (biz) {
            let branch = (biz.branches || []).find(branch => {
                return branch.code == brCode
            })

            if (branch && branch.tables) {
                var retTables = []

                for (var a = 0; a < branch.tables; a++) {
                    retTables.push({
                        name: "Table " + a,
                        seats: 4
                    })
                }
                return retTables || []
            } else {
                return []
            }
        } else {
            return []
        }
    }

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const getBusinessBranches = (code) => {
        let biz = businessProfiles.find(business => {
            return business.code == code
        })

        if (biz) {
            return biz.branches || []
        } else {
            return []
        }
    }
    const downloadQRCode = (e) => {
        e.preventDefault();
        let canvas = qrRef.current.querySelector("canvas");
        let image = canvas.toDataURL("image/png");
        let anchor = document.createElement("a");
        anchor.href = image;
        anchor.download = `qr-code.png`;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        setUrl("");
    };

    const qrCodeEncoder = (e) => {
        setUrl(e.target.value);
    };

    const qrcode = (
        <Box>
            <Paper id="qrCodeArea" sx={{
                backgroundColor: "#ccc",
                width: 300,
                height: 300,
                padding: 3
            }}>
                <QRCodeCanvas
                    id="qrCode"
                    value={url}
                    size={260}
                    bgColor={"#fff"}
                    level={"H"}
                    includeMargin={true}
         />
          </Paper>
         </Box>

    );

    return (
        <Box sx={{ width: '80%', margin: 'auto', paddingTop: '10%' }}>
            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    if (isStepOptional(index)) {
                        labelProps.optional = (
                            <Typography variant="caption">Optional</Typography>
                        );
                    }
                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            {activeStep === steps.length ? (
                <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                        All steps completed - you&apos;re finished
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button onClick={handleReset}>Reset</Button>
                    </Box>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    {activeStep === 0 && (
                        <React.Fragment>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Business</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={business}
                                    label="Business"
                                    onChange={handleBusinessChange}
                                >
                                    {(businessProfiles || []).map((business, index) => {
                                        return (<MenuItem key={index} value={business.code}>
                                            {business.name}
                                        </MenuItem>)
                                    })

                                    }
                                </Select>
                            </FormControl>
                        </React.Fragment>
                    )}
                    {activeStep === 1 && (
                        <React.Fragment>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Business</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={branch}
                                    label="Branch"
                                    onChange={handleBranchChange}
                                >
                                    {(getBusinessBranches(business) || []).map((branch, index) => {
                                        return (<MenuItem key={index} value={branch.code}>
                                            {branch.name}
                                        </MenuItem>)
                                    })

                                    }
                                </Select>
                            </FormControl>
                        </React.Fragment>
                    )}
                    {activeStep === 2 && (
                        <React.Fragment sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between'
                        }}>
                            {getTableSortLabelUtilityClass(business, branch).map(tablet => {
                                return (<Button sx={{
                                    margin: '1em',
                                    color: (table.name == tablet.name) ? 'white' : 'black'
                                }}
                                    onClick={() => { setTable(tablet) }}
                                    variant="contained">{tablet.name}</Button>)
                            })}
                        </React.Fragment>
                    )}
                    {activeStep === 3 && (
                        <React.Fragment sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between'
                        }}>
                            <div className="qrcode__container">
                                <div>Menu Galore Table QR Code Generator</div>
                                <div>

                                </div>
                                <div ref={qrRef}>{qrcode}</div>
                                <div className="input__group">
                                    <form onSubmit={downloadQRCode}>


                                        <button type="submit" disabled={!url}>
                                            Download QR code
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Button
                            color="inherit"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                        >
                            Back
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        {isStepOptional(activeStep) && (
                            <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                                Skip
                            </Button>
                        )}

                        <Button onClick={handleNext}>
                            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                        </Button>
                    </Box>
                </React.Fragment>
            )}
        </Box>
    );
}