import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { Badge } from '@mui/material';


const emails = ['username@gmail.com', 'user02@gmail.com'];

function Dishes(props) {
    const { onClose, whenAddToOrder, open, category, dishes } = props;

    const [spiceLevel, setSpiceLevel] = React.useState("")


    const handleAddToOrder = (value) => {
        whenAddToOrder(value);
    };

    return (
        <Dialog onClose={handleAddToOrder} open={open}>
            <DialogTitle>{category.name}</DialogTitle>
            <List sx={{ pt: 0 }}>
                {dishes.map((dish) => (

                    <ListItem card key={dish.code}>
                        <Divider>
                        <ListItemText primary={dish.name} />
                        </Divider>
                        <Stack>
                         



                            <ListItemText
                                sx={{
                                    marginLeft: '5px'
                                }}
                                primaryTypographyProps={{ fontSize: '8px' }}
                                primary={dish.desc} />
                        </Stack>

                        <Stack direction="row" spacing={1}>
                            <Chip onClick = {() => {
                                setSpiceLevel("mild")
                            }} size="small" label="mild" sx={{ bgcolor: '#fdd835', fontSize: '8px' }} variant="outlined" />
                            <Chip onClick = {() => {
                                setSpiceLevel("medium hot")
                            }} size="small" label="medium hot" sx={{ bgcolor: '#fb8c00', fontSize: '8px' }} variant="outlined" />
                            <Chip onClick = {() => {
                                setSpiceLevel("hot")
                            }} size="small" label="hot" sx={{ bgcolor: '#f4511e', fontSize: '8px' }} variant="outlined" />
                            <Chip onClick = {() => {
                                setSpiceLevel("extra hot")
                            }} size="small" label="extra hot" sx={{ bgcolor: '#e53935', fontSize: '8px' }} variant="outlined" />
                        </Stack>
                        <Badge anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }} badgeContent={"$" + dish.price} color="secondary" sx={{ margin: "12px" }}>
                            <Button onClick={
                                () => {
                                    handleAddToOrder({
                                        name: dish.name,
                                        spiceLevel: spiceLevel,
                                        price: dish.price
                                    })
                                }
                            }>Add to Order</Button>
                        </Badge>

                    </ListItem>

                ))}
            </List>

        </Dialog>
    );
}

Dishes.propTypes = {
    onClose: PropTypes.func.isRequired,
    whenAddToOrder: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    category: PropTypes.object.isRequired,
    dishes: PropTypes.array.isRequired
};

export default Dishes;

