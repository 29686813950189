


import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyCX70cBloHCmPtYq09KmsJp1gUqbJm2lRk",
    authDomain: "prime-6d78f.firebaseapp.com",
    databaseURL: "https://prime-6d78f-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "prime-6d78f",
    storageBucket: "prime-6d78f.appspot.com",
    messagingSenderId: "194666596150",
    appId: "1:194666596150:web:6b73865a71f0c53825d597",
    measurementId: "G-Q14W1D2R65"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Realtime Database and get a reference to the service
const fireDb = getDatabase(app);
export default fireDb