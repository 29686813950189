import logo from './logo.svg';
import TableGenerator from './components/table-generator';
import { BrowserRouter as Router, Switch, Route, Link, Routes } from 'react-router-dom';
import './App.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { green, grey } from '@mui/material/colors';
import Menu from './components/menu';
import Kitchen from "./components/kitchen"

const darkTheme = createTheme({
  palette: {
    mode: 'dark',

    // palette values for dark mode
    primary: {
      main: green[500]
    },


    divider: green[700],
    background: {
      default: '#111',
      paper: '#111',
    },
    text: {
      primary: '#fff',
      secondary: grey[500],
    },

  },
});

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <div className="App">

          <div className="App">

            <Router>
            <ul >
              <li>
                <Link to="/">Menu Galore</Link>
              </li>
              <li>
                <Link to="/menu">Menu</Link>
              </li>
              <li>
                <Link to="/table-generator">Table Generator</Link>
              </li>
              <li>
                <Link to="/kitchen">Kitchen</Link>
              </li>
            </ul>
              <Routes>
                <Route exact path='/' element={< Menu />}></Route>
                <Route exact path='/menu' element={< Menu />}></Route>
                <Route exact path='/kitchen' element={< Kitchen />}></Route>
                <Route exact path='/table-generator' element={< TableGenerator />}></Route>
              </Routes>
            </Router>



          </div>

      </div>
    </ThemeProvider>
  );
}

export default App;
