import React from 'react'

import { ref, onValue } from "firebase/database";
import fireDb from '../firebase';
import { Paper, Box, Badge } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';



function Kitchen() {

    const [orders, setOrders] = React.useState([])

    React.useEffect(() => {
        const starCountRef = ref(fireDb, 'orders/');
        onValue(starCountRef, (snapshot) => {
            let ord = snapshot.val();
            ord = Object.values(ord)
            if (orders.length !== ord.length) {
                setOrders(ord)
            }

        });
    }, [orders]);




    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'row'
        }}>


            {
                orders.map(order => {
                    return (<Box>
                        <Paper sx={{
                            margin: 10,
                            backgroundColor: "#eee",
                            color: "black"
                        }}>
                            <React.Fragment>
                                <CardContent>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                        {order.order}
                                    </Typography>

                                </CardContent>
                                <CardActions sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent:"center",
                                    alignItems: "flex-start"
                                }}>
                                    {(order.items || []).map(item => {

                                        return (<Box>
                                            <Button>
                                                <Badge color="warning" badgeContent={""} showZero>

                                                </Badge>
                                                <Typography marginLeft={2}>
                                                    {item.name}
                                                </Typography>


                                            </Button>

                                        </Box>

                                        )
                                    })}
                                </CardActions>
                            </React.Fragment>



                        </Paper>
                    </Box>)
                })
            }
        </Box>
    )
}

export default Kitchen